<template>
    <div>
        <wit-input-group
            v-model="internalClientId"
            :model="clientIdModel"
            type="text"
            input-label="Client ID"
            placeholder="Enter your Client ID"
            invalid="This field is required"
            valid="Client ID is valid"
            :step="step"
            :id="`${formId}-clientId`"
            :disabled="disabled"
            @input="emitClientId"
        />
        <wit-input-group
            v-model="internalClientSecret"
            :model="clientSecretModel"
            type="password"
            input-label="Client Secret"
            placeholder="Enter your Client Secret"
            invalid="This field is required"
            valid="Client Secret is valid"
            :step="step"
            :id="`${formId}-clientSecret`"
            :disabled="disabled"
            @input="emitClientSecret"
        />
    </div>
</template>

<script>
import WitInputGroup from '@/components/Inputs/WitInputGroup.vue'

export default {
    name: 'o-auth-2',
    components: {
        WitInputGroup,
    },
    props: {
        clientIdValue: {
            required: true,
        },
        clientIdModel: {
            required: true,
        },
        clientSecretValue: {
            required: true,
        },
        clientSecretModel: {
            required: true,
        },
        formId: {
            required: true,
        },
        step: {
            required: true,
        },
        disabled: {
            type: Boolean,
        },
    },
    data() {
        return {
            internalClientId: '',
            internalClientSecret: '',
        }
    },
    watch: {
        clientIdValue() {
            this.internalClientId = this.clientIdValue
        },
        clientSecretValue() {
            this.internalClientSecret = this.clientSecretValue
        },
    },
    created() {
        if (this.clientIdValue) {
            this.internalClientId = this.clientIdValue
        }
        if (this.clientSecretValue) {
            this.internalClientSecret = this.clientSecretValue
        }
    },
    methods: {
        emitClientId() {
            this.$emit('update:clientIdValue', this.internalClientId)
            this.$emit('change')
        },
        emitClientSecret() {
            this.$emit('update:clientSecretValue', this.internalClientSecret)
            this.$emit('change')
        },
    },
}
</script>
